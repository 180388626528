<template>
    <div class="home">
      <!-- 英雄区域 -->
      <section class="hero">
        <div class="hero-content">
          <h1>北京艾克希企业管理有限公司</h1>
          <p class="subtitle">专业的解决方案，创新的技术服务</p>
          <div class="button-group">
            <button class="cta-button primary" @click="scrollToServices">了解更多</button>
            <a href="mailto:support@ai-xtech.com" class="cta-button secondary">联系我们</a>
          </div>
        </div>
      </section>
  
      <!-- 服务介绍 -->
      <section class="services" ref="servicesSection">
        <h2>我们的服务</h2>
        <div class="service-grid">
          <div class="service-card">
            <i class="fas fa-laptop-code"></i>
            <h3>网站开发</h3>
            <p>专业的网站开发服务</p>
          </div>
          <div class="service-card">
            <i class="fas fa-mobile-alt"></i>
            <h3>移动应用</h3>
            <p>iOS和Android应用开发</p>
          </div>
          <div class="service-card">
            <i class="fas fa-cloud"></i>
            <h3>云服务</h3>
            <p>可靠的云计算解决方案</p>
          </div>
        </div>
      </section>
  
      <!-- 添加底部 banner -->
      <footer class="footer">
        <div class="footer-content">
          <div class="footer-info">
            <div class="company-info">
              <h4>北京艾克希企业管理有限公司</h4>
              <h5>Beijing Aikexi Business Management Co., Ltd.</h5>
              <p>地址：北京市丰台区石榴园北里42号</p>
              <p>邮箱：Support@ai-xtech.com</p>
            </div>
            <div class="footer-links">
              <a href="#">关于我们</a>
              <a href="#">服务条款</a>
              <a href="#">隐私政策</a>
            </div>
          </div>
          <div class="copyright">
            <p>&copy; 2024 北京艾克希企业管理有限公司 版权所有</p>
          </div>
        </div>
      </footer>
    </div>
  </template>
  
  <script>
  export default {
    name: 'HomePage',
    methods: {
      scrollToServices() {
        this.$refs.servicesSection.scrollIntoView({ 
          behavior: 'smooth',
          block: 'start'
        });
      }
    }
  }
  </script>
  
  <style scoped lang="scss">
  .home {
    .hero {
      min-height: 70vh;
      height: auto;
      padding: 4rem 0;
      background: linear-gradient(135deg, #1e3c72 0%, #2a5298 100%);
      position: relative;
      overflow: hidden;
  
      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: radial-gradient(circle at 30% 30%, rgba(255, 255, 255, 0.1) 0%, transparent 50%),
                    radial-gradient(circle at 70% 70%, rgba(255, 255, 255, 0.1) 0%, transparent 50%);
        pointer-events: none;
      }
  
      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: linear-gradient(45deg, rgba(255, 255, 255, 0.05) 25%, 
                    transparent 25%, transparent 50%, 
                    rgba(255, 255, 255, 0.05) 50%, rgba(255, 255, 255, 0.05) 75%, 
                    transparent 75%, transparent);
        background-size: 60px 60px;
        opacity: 0.1;
        pointer-events: none;
      }
  
      .hero-content {
        position: relative;
        max-width: 800px;
        margin: 0 auto;
        padding: 4rem 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
  
        h1 {
          font-size: clamp(1.8rem, 4vw, 3.2rem);
          font-weight: 700;
          margin-bottom: 1.5rem;
          animation: fadeInDown 1s ease;
          color: white;
        }
  
        .subtitle {
          font-size: clamp(1rem, 2vw, 1.4rem);
          margin-bottom: 2.5rem;
          opacity: 0.9;
          animation: fadeInUp 1s ease 0.3s;
          color: rgba(255, 255, 255, 0.9);
        }
  
        .button-group {
          display: flex;
          gap: 1rem;
          justify-content: center;
          animation: fadeIn 1s ease 0.6s;
  
          @media (max-width: 768px) {
            flex-direction: column;
            align-items: center;
          }
        }
  
        .cta-button {
          padding: 1rem 2.5rem;
          font-size: 1.1rem;
          border-radius: 30px;
          font-weight: 600;
          transition: all 0.3s ease;
  
          &.primary {
            background-color: #007bff;
            color: white;
            border: none;
  
            &:hover {
              background-color: #0056b3;
              transform: translateY(-2px);
            }
          }
  
          &.secondary {
            background-color: transparent;
            color: white;
            border: 2px solid white;
  
            &:hover {
              background-color: white;
              color: #1e3c72;
            }
          }
        }
      }
    }
  
    .services {
      padding: 6rem 2rem;
      background-color: #f8f9fa;
  
      h2 {
        font-size: 2.5rem;
        text-align: center;
        margin-bottom: 4rem;
        position: relative;
  
        &::after {
          content: '';
          position: absolute;
          bottom: -15px;
          left: 50%;
          transform: translateX(-50%);
          width: 60px;
          height: 3px;
          background-color: #007bff;
        }
      }
  
      .service-grid {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 3rem;
  
        @media (max-width: 768px) {
          grid-template-columns: 1fr;
        }
  
        .service-card {
          padding: 2rem;
          text-align: center;
          background: white;
          border-radius: 8px;
          transition: transform 0.3s ease, box-shadow 0.3s ease;
          border: 1px solid #eee;
  
          &:hover {
            transform: translateY(-10px);
            box-shadow: 0 10px 30px rgba(0,0,0,0.1);
          }
  
          i {
            font-size: 3rem;
            background: linear-gradient(135deg, #007bff, #00ff88);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }
      }
    }
  }
  
  @keyframes fadeInDown {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  .footer {
    background-color: #1e3c72;
    color: white;
    padding: 3rem 0 2rem;
  
    .footer-content {
      max-width: 1200px;
      margin: 0 auto;
      padding: 0 2rem;
  
      .footer-info {
        display: flex;
        justify-content: space-between;
        margin-bottom: 2rem;
        
        @media (max-width: 768px) {
          flex-direction: column;
          gap: 2rem;
        }
  
        .company-info {
          h4 {
            font-size: 1.2rem;
            margin-bottom: 1rem;
            font-weight: 600;
          }
  
          p {
            color: rgba(255, 255, 255, 0.8);
            margin-bottom: 0.5rem;
            font-size: 0.9rem;
          }
        }
  
        .footer-links {
          display: flex;
          gap: 2rem;
  
          @media (max-width: 768px) {
            flex-wrap: wrap;
            gap: 1rem;
          }
  
          a {
            color: rgba(255, 255, 255, 0.8);
            text-decoration: none;
            transition: color 0.3s ease;
  
            &:hover {
              color: white;
            }
          }
        }
      }
  
      .copyright {
        padding-top: 2rem;
        border-top: 1px solid rgba(255, 255, 255, 0.1);
        text-align: center;
  
        p {
          color: rgba(255, 255, 255, 0.6);
          font-size: 0.9rem;
          margin-bottom: 0.5rem;
        }
      }
    }
  }
  </style>